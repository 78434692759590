import { Link } from "react-router-dom";
import soundRentalData from '../../components/SoundRental/soundRental.json';
import TextToSpeech from '../../TextToSpeech/textToSpeech';

const text =
  "Äänentoiston vuokraus. Meiltä löydät laajan valikoiman äänentoistovuokrausratkaisuja kaikenlaisiin tilaisuuksiin. Kotibileistä suurempiin tapahtumiin!!";

const SoundRental = () => {
  return (
    <div className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 pb-10 pt-7 pl-5 pr-5">
      <div className="container">
        <div className="flex flex-row">
          <h2 className="text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight text-left font-customFont2 text-darkest dark:text-primary text-shadow-lg">
            ÄÄNENTOISTON VUOKRAUS
          </h2>

          <div className='speech-title ml-5 flex justify-center'>
            <TextToSpeech text={text} />
          </div> 
        </div>

        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl">
          Meiltä löydät laajan valikoiman äänentoistovuokrausratkaisuja kaikenlaisiin tilaisuuksiin – kotibileistä suurempiin tapahtumiin!!
        </p>
        <ul className="ominaisuudet list-disc font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl p-4 pb-10">
          <li><strong>Kotibileet:</strong> Pienet ja kätevät äänentoistopaketit, jotka sopivat täydellisesti musiikin kuunteluun ja tunnelman luomiseen kotibileissä.</li>
          <li><strong>Juhlat ja tapahtumat:</strong> Tehokkaammat äänentoistopaketit, jotka sopivat isompiin juhliin ja tapahtumiin, kuten syntymäpäiväjuhliin, häihin ja yritystapahtumiin.</li>
          <li><strong>Bändit ja livemusiikki:</strong> Ammattitason äänentoistoratkaisut, jotka täyttävät kaikki vaatimukset ja takaavat laadukkaan äänentoiston bändien ja muiden live-esiintyjien keikoilla.</li>
        </ul>
        <div>
          <div className="grid grid-cols-1 xxsm:grid-cols-2 xsm:grid-cols-2 bv9900:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 mdd:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
            {soundRentalData.map((item) => (
              <div key={item.name} className="bg-white bg-opacity-50 hover:bg-lightbg hover:bg-opacity-60 rounded-3xl relative group p-5 flex flex-col justify-between">
                <TextToSpeech text={item.text} />
                <div className="w-full h-[120px]">
                  <Link to={item.link} title={item.name} onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label={`Näytä ${item.name} tuotekuvaus`}>
                    <img src={item.image} alt={item.name} title={item.name} loading="lazy" className="w-full h-[150px] object-contain sm:translate-x-0 transition duration-700 cursor-pointer drop-shadow-[3px_3px_5px_rgba(0,0,0,0.80)]" />
                  </Link>
                </div>

                <div className="flex flex-col justify-between flex-grow">
                  <div className="grid grid-cols-1 place-items-center pt-10">
                    <h3 className="font-customFont1 text-shadow-lg text-center text-darkest dark:text-primary xxxsm:text-sm xxsm:text-base xsm:text-lg sm:text-xl md:text-2xl mdd:text-1xl lg:text-2xl xl:text-3xl font-normal pb-5" title={item.name}>{item.name}</h3>
                  </div>
                  
                  <div className="mt-auto text-center">
                    <div className="font-customFont1 text-darkest dark:text-primary text-shadow-lg font-normal pb-2">
                      <p className="specs font-customFont1 text-darkest dark:text-primary text-shadow-lg xxxsm:text-base xxsm:text-lg xsm:text-xl sm:text-2xl md:text-3xl mdd:text-2xl lg:text-4xl xl:text-4xl font-normal"> {item.price} € / 24h</p>
                    </div>
                    <div className="flex justify-center">
                      <Link to={item.link} title="Katso lisätiedot" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label={`Katso lisätiedot ${item.name}`}>
                        <button className="h-12 w-28 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out opacity-75" title="Katso lisätiedot">Lisätiedot</button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="grid place-content-center mt-4">
                  <Link to="/otayhteytta" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label="Tiedustele saatavuutta">
                    <button className="h-12 w-40 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out font-customFont1 text-center text-shadow-lg font-light" title="Ota yhteyttä!">
                      Kysy saatavuutta
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SoundRental;
