import { Link } from "react-router-dom";
import lightRentalData from "../LightRental/lightRental.json";
import TextToSpeech from '../../TextToSpeech/textToSpeech';

const text =
  "Esitystekniikan vuokraus. Äänentoisto on vasta alkua! Nosta tapahtumasi seuraavalle tasolle upeilla valaistus- ja savutehosteilla. Oikeanlainen esitystekniikka luo unohtumattoman tunnelman ja tekee vaikutuksen yleisöösi. ";

const LightRental = () => {

  return (
    <div className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 pb-10 pt-7 pl-5 pr-5">
      <div className="container">

        {/* SPEECH */}
        <div className="flex flex-row flex-wrap">
          <h2 id="esitystekniikanvuokraus" className="text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-semibold leading-tight text-left font-customFont2 text-darkest dark:text-primary text-shadow-lg">
            ESITYSTEKNIIKAN VUOKRAUS
          </h2>

          <div className='speech-title ml-5 flex justify-center'>
            <TextToSpeech text={text} />
          </div> 
        </div>

        <div className="video-container my-5">
          <iframe 
            src="https://www.youtube.com/embed/AjVsYdQ9mAo?si=K0BtB9q-cF1u7fZE" 
            title="YouTube video player" 
            frameBorder="0" 
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
            allowFullScreen>
          </iframe>
        </div>

        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal pb-10">
          <strong>Äänentoisto on vasta alkua!</strong> Nosta tapahtumasi seuraavalle tasolle upeilla valaistus- ja savutehosteilla. Oikeanlainen esitystekniikka luo unohtumattoman tunnelman ja tekee vaikutuksen yleisöösi.
        </p>

        <div>
          <div className="grid grid-cols-1 xxsm:grid-cols-2 xsm:grid-cols-2 bv9900:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 mdd:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
            {lightRentalData.map((data) => (
              <div key={data.name} className="bg-white bg-opacity-50 hover:bg-lightbg hover:bg-opacity-60 rounded-3xl relative group p-5 flex flex-col justify-between">
                <TextToSpeech text={data.text} />

                {/* Kuva */}
                <div className="w-full h-[120px]">
                  <Link to={data.link} title="tuotekuvaus" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label={`Katso lisätiedot: ${data.name}`}>
                    <img 
                      src={data.image} 
                      alt={data.name} 
                      title={data.name} 
                      loading="lazy" 
                      className="w-full h-[150px] object-contain sm:translate-x-0 transition duration-700 cursor-pointer drop-shadow-[3px_3px_5px_rgba(0,0,0,0.80)]" 
                    />
                  </Link>
                </div>

                {/* Tekstit */}
                <div className="flex flex-col justify-between flex-grow">
                  <div className="grid grid-cols-1 place-items-center pt-10">
                    <h3 className="font-customFont1 text-shadow-lg text-center text-darkest dark:text-primary xxxsm:text-sm xxsm:text-base xsm:text-lg sm:text-xl md:text-2xl mdd:text-1xl lg:text-2xl xl:text-3xl font-normal pb-5" title={data.name} aria-label={data.name}>{data.name}</h3>
                  </div>
                  
                  <div className="mt-auto text-center">
                    <div className="font-customFont1 text-darkest dark:text-primary text-shadow-lg font-normal pb-2">
                      <p className="specs font-customFont1 text-darkest dark:text-primary text-shadow-lg xxxsm:text-base xxsm:text-lg xsm:text-xl sm:text-2xl md:text-3xl mdd:text-2xl lg:text-4xl xl:text-4xl font-normal"> {data.price} € / 24h</p>
                    </div>
                    <div className="flex justify-center">
                      <Link to={data.link} title="tuotekuvaus" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label={`Katso lisätiedot: ${data.name}`}>
                        <button className="h-12 w-28 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out opacity-75" title="Katso lisätiedot">Lisätiedot</button>
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="grid place-content-center mt-4">
                  <Link to="/otayhteytta" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label="Kysy saatavuutta">
                    <button className="h-12 w-40 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out font-customFont1 text-center text-shadow-lg font-light" title="Ota yhteyttä!">
                      Kysy saatavuutta
                    </button>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
};

export default LightRental;
