import { useState } from 'react';
import { Link } from 'react-router-dom';
import TextToSpeech from '../../TextToSpeech/textToSpeech';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton 
} from 'react-share';

import { 
    FacebookIcon,
    XIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

const text =
  "JBL Partybox 300 + langattomat mikrofonit. Valmistaudu unohtumattomiin bileisiin: JBL PartyBox on täällä! Unohda tavalliset kaiuttimet ja anna tämän mullistaa juhlasi! Kaiutin ei pelkästään tarjoa yllättävän hyvää äänenlaatua, vaan myös makeita valoefektejä, jotka luovat ainutlaatuisen tunnelman.";

const text1 =
  "Vie bileet minne tahansa. Kaiuttimen massiivinen akku takaa jopa kahdeksantoistatuntia musiikkia. Voit liittää kaiuttimen myös verkkovirtaan tai kahdentoista voltin järjestelmään, joten juhlat voivat jatkua tauotta."; 

const text2 =
  "Toista musiikkia mistä tahansa. Striimaa lempikappaleesi Bluetoothin, audio-kaapelin tai USB-muistin kautta. Lisäominaisuudet. Käytännöllinen kantokahva, Vedenpitävä IPX4-luokitus, Kätevä kantolaukku kautta reppu, Bass Boost -toiminto ja Säädettävä mikrofonivahvistus.";

const text3 =
  "Hinta. Alkaen viisikymmentä euroa vuorokausi. Tiedustele saatavuutta painamalla alla olevaa nappia.";

const text4 =
  "Karaokea tai puheita, kaikki sujuu langattomasti! Paketin mukana tulevat JBL:n langattomat mikrofonit tekevät juhlista entistäkin hauskempaa. Voit pitää puheita tai laulaa karaokea, valitse itse!.";

import JBL from '../../assets/JBL_logo.webp';
import PartyBoxImage from '../../assets/JBL_Partybox_300.webp';
import Microphonesbox from '../../assets/JBL_Wireless_Microphones.webp';
import Microphones from '../../assets/JBL microphones.webp';

const PartyBox300 = () => {
    const [activeImg, setActiveImage] = useState(PartyBoxImage);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    return (
        <>
            {/* Back button */}
            <button 
                onClick={() => { window.history.back(); }} 
                className="h-12 w-32 dark:bg-violet bg-primary dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none"
                aria-label="Takaisin"
            >
                Takaisin
            </button>

            {/* Tuotteen kuvat */}
            <div className="flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5">
                <div className="flex flex-col gap-5">
                    <img 
                        src={activeImg} 
                        alt="JBL Partybox 300 + mikit" 
                        title="JBL PartyBox 300 + mikit" 
                        className="w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]" 
                    />
                    
                    <div className="flex gap-5 flex-wrap justify-center drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]">
                        {/* Korvataan <img> -elementit <button> -elementeillä saavutettavuuden parantamiseksi */}
                        <button 
                            onClick={() => handleImageClick(PartyBoxImage)} 
                            className="w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none"
                            aria-label="Näytä JBL PartyBox 300"
                        >
                            <img 
                                src={PartyBoxImage} 
                                alt="JBL PartyBox 300" 
                                title="JBL PartyBox 300" 
                                className="w-full h-full object-cover rounded-md" 
                            />
                        </button>
                        <button 
                            onClick={() => handleImageClick(Microphonesbox)} 
                            className="w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none"
                            aria-label="Näytä JBL Wireless Microphones"
                        >
                            <img 
                                src={Microphonesbox} 
                                alt="JBL Wireless Microphones" 
                                title="JBL Wireless Microphones" 
                                className="w-full h-full object-cover rounded-md" 
                            />
                        </button>
                        <button 
                            onClick={() => handleImageClick(Microphones)} 
                            className="w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none"
                            aria-label="Näytä JBL microphones"
                        >
                            <img 
                                src={Microphones} 
                                alt="JBL microphones" 
                                title="JBL microphones" 
                                className="w-full h-full object-cover rounded-md" 
                            />
                        </button>
                    </div>
                    
                    {/* Tuotteen videot */}
                    <h2 className="font-customFont2 text-shadow-lg text-center text-primary dark:text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold">Videot</h2>
                    <div className="flex gap-5 flex-wrap justify-center aspect-video">
                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/MnWWDDLlYfM?si=W2Aj-lvFOUFtze3N"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="JBL PartyBox 300 esittelyvideo"
                        ></iframe>
                    </div>
                </div>

                {/* Tuotteen kuvaus */}
                <div className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl">
                    <div>
                        {/* SPEECH */}
                        <div className="flex flex-row items-center">
                            {/* Otsikko h1 */}
                            <h1 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none">
                                JBL Partybox 300 + mikit
                            </h1>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text} />
                            </div>    
                        </div>

                        <br />

                        {/* Otsikko h2 */}
                        <h2 className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
                            Valmistaudu unohtumattomiin bileisiin: JBL PartyBox 300 on täällä!
                        </h2>
                        <br />

                        {/* Teksti */}
                        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
                            Unohda tavalliset kaiuttimet ja anna tämän mullistaa juhlasi! Kaiutin ei pelkästään tarjoa <strong>yllättävän hyvää äänenlaatua</strong>, vaan myös <strong>makeita valoefektejä</strong>, jotka luovat ainutlaatuisen tunnelman.
                        </p>
                        <br />

                        {/* SPEECH */}
                        <div className="flex flex-row items-center">
                            <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
                                Vie bileet minne tahansa!
                            </p>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text1} />
                            </div>
                        </div>
                        <br />

                        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
                            Kaiuttimen <strong>massiivinen akku</strong> takaa jopa <strong>18 tuntia</strong> musiikkia. Voit liittää kaiuttimen myös verkkovirtaan tai 12 voltin järjestelmään, joten juhlat voivat jatkua tauotta.
                        </p>
                        <br />

                        {/* SPEECH */}
                        <div className="flex flex-row items-center">
                            <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
                                Karaokea tai puheita – kaikki sujuu langattomasti!
                            </p>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text4} />
                            </div>
                        </div>
                        <br />

                        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
                            Paketin mukana tulevat <strong>JBL:n langattomat mikrofonit</strong> tekevät juhlista entistäkin hauskempaa. Voit pitää puheita tai laulaa karaokea, valitse itse!
                        </p>
                        <br />

                        {/* SPEECH */}
                        <div className="flex flex-row items-center">
                            <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
                                Toista musiikkia mistä tahansa!
                            </p>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text2} />
                            </div>
                        </div>
                        <br />

                        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
                            Striimaa lempikappaleesi <strong>Bluetoothin, audio-kaapelin tai USB-muistin kautta.</strong>
                        </p>
                        <br />
                        <p className="ominaisuudet font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
                            Lisäominaisuudet:
                        </p>
                        <ul className="ominaisuudet list-disc font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal p-4">
                            <li>Käytännöllinen kantokahva</li>
                            <li>Vedenpitävä IPX4-luokitus</li>
                            <li>Kätevä kantolaukku/reppu</li>
                            <li>Bass Boost -toiminto</li>
                            <li>Säädettävä mikrofonivahvistus</li>
                        </ul>
                        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
                            Älä tyydy vähempään – valitse JBL PartyBox 300 ja sytytä bileet liekkeihin!
                        </p>
                        
                        <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">
                            <FacebookShareButton
                                url={"https://spiderlegsproductions.com/bilekaiutin-mikrofoneilla"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={"https://spiderlegsproductions.com/bilekaiutin-mikrofoneilla"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <XIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={"https://spiderlegsproductions.com/bilekaiutin-mikrofoneilla"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <WhatsappIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={"https://spiderlegsproductions.com/bilekaiutin-mikrofoneilla"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <LinkedinIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={"https://spiderlegsproductions.com/bilekaiutin-mikrofoneilla"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <EmailIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </EmailShareButton>
                        </div>

                        {/* SPEECH */}
                        <div className="flex flex-row items-center">
                            {/* Hinta */}
                            <h2 
                                title="Hinta" 
                                className="font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold"
                            >
                                Alk. 50€ / vrk
                            </h2>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text3} />
                            </div>
                        </div>

                        {/* Kysele saatavuutta button */}
                        <div className="flex flex-row items-center gap-12 pt-5">
                            <Link to="/otayhteytta" onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'auto' }); }}>
                                <button 
                                    title="Tiedustele saatavuutta" 
                                    className="h-12 w-60 dark:bg-primary 
                                        dark:hover:border-2
                                        dark:hover:opacity-90
                                        dark:border-white
                                        dark:text-darkest 

                                        bg-darkest 
                                        hover:border-2
                                        hover:border-opacity-90
                                        hover:opacity-90
                                        border-linkcolhover
                                        text-primary 
                                    
                                        font-customFont1 
                                        font-bold 
                                        text-1xl 
                                        overflow-hidden 
                                        transform hover:scale-x-110 
                                        hover:scale-y-105 transition 
                                        duration-300 ease-in
                                        items-center 
                                        rounded-full 
                                        cursor-pointer focus:outline-none" 

                                    style={{
                                        boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)"
                                    }}
                                >
                                    Tiedustele saatavuutta
                                </button>
                            </Link>
                        </div>

                        {/* Tuotteen logo */}
                        <div className="flex flex-wrap place-items-start pt-5">
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                {/* Tuotteen logo */}
                                <a href="https://fi.jbl.com/" aria-label="Vieraile JBL:n sivuilla.">
                                    <img 
                                        src={JBL}  
                                        alt="JBL logo" 
                                        loading="lazy" 
                                        title="Vieraile JBL:n sivuilla." 
                                        className="w-full h-full object-contain rounded-xl" 
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PartyBox300;
