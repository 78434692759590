import { useState } from 'react';
import { Link } from "react-router-dom";
import TextToSpeech from '../../TextToSpeech/textToSpeech';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton 
} from 'react-share';

import { 
    FacebookIcon,
    XIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

const text = "ADJiin, my deeämäks gou, langaton deeämäks-ohjain";

const text1 = "ADJiin my Deeämäks gou on vallankumouksellinen uusi valaistuksen ohjausjärjestelmä, joka on erittäin tehokas ja uskomattoman helppokäyttöinen. My DMX gou -sovellus ei vaadi ohjelmointia, mutta sitä voidaan käyttää luomaan upeita synkronoituja valoesityksiä missä tahansa valaisinyhdistelmässä. Muutamassa sekunnissa voidaan luoda helposti uskomattomia valaistusnäyttöjä, jotka vaativat tuntikausia ohjelmointia perinteisillä ohjausjärjestelmillä. Yli viidentoistatuhannen profiilin kattavan valaisinkirjaston ansiosta my DMX gouta voidaan käyttää kaikentyyppisten Deeämäks-valaistuksen ohjaamiseen mistä tahansa valmistajasta. Se on ihanteellinen liikkuvien viihdyttäjien käyttöön sekä pieniin yökerhoihin, baareihin ja vapaa-ajan kohteisiin, joissa tarvitaan yksinkertainen ja helppokäyttöinen valaistuksen ohjausjärjestelmä.";

const text2 = "Hinta. Alkaen viisikymmentä euroa vuorokausi. Tiedustele saatavuutta painamalla alla olevaa nappia.";

// Images
import ADJ from '../../assets/ADJ_logo.webp';
import img1 from '../../assets/ADJMyDMXGO1.webp';
import img2 from '../../assets/ADJMyDMXGO2webp.webp';
import img3 from '../../assets/ADJMyDMXGO3webp.webp';
import img4 from '../../assets/ADJMyDMXGO4webp.webp';

const ADJmyDMX = () => {
    const [activeImg, setActiveImage] = useState(img1);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    return (
        <>
            {/* Takaisin-nappula */}
            <button 
                onClick={() => { window.history.back(); }} 
                className="h-12 w-32 dark:bg-violet bg-primary dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out"
                aria-label="Palaa takaisin"
            >
                Takaisin
            </button>

            {/* Tuotteen kuvat */}
            <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>
                <div className='flex flex-col gap-5'>
                    <img 
                        src={activeImg} 
                        alt='ADJ myDMX GO langaton DMX-ohjain' 
                        title="ADJ myDMX GO langaton DMX-ohjain" 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]' 
                    />
                    <div className='flex gap-5 flex-wrap justify-center drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>
                        {/* Kuvien ympäröiminen button-elementeillä saavutettavuuden parantamiseksi */}
                        <button 
                            onClick={() => handleImageClick(img1)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Katso ADJ myDMX GO Etu"
                        >
                            <img 
                                src={img1} 
                                alt="ADJ myDMX GO Etukuva" 
                                title="ADJ myDMX GO Etukuva" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                        <button 
                            onClick={() => handleImageClick(img2)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Katso ADJ myDMX GO Sivukuva"
                        >
                            <img 
                                src={img2} 
                                alt="ADJ myDMX GO Sivukuva" 
                                title="ADJ myDMX GO Sivukuva" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                        <button 
                            onClick={() => handleImageClick(img3)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Katso ADJ myDMX GO Paneelikuva"
                        >
                            <img 
                                src={img3} 
                                alt="ADJ myDMX GO Paneelikuva" 
                                title="ADJ myDMX GO Paneelikuva"  
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                        <button 
                            onClick={() => handleImageClick(img4)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Katso ADJ myDMX GO Kaukosäädin"
                        >
                            <img 
                                src={img4} 
                                alt="ADJ myDMX GO Kaukosäädin" 
                                title="ADJ myDMX GO Kaukosäädin"  
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                    </div>

                    {/* Tuotteen videot */}
                    <h2 className='font-customFont2 text-shadow-lg text-center text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>Videot</h2>
                    <div className="flex gap-5 flex-wrap justify-center aspect-video">
                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/7gXPzcXNHWE?si=F4oRxOoDE6rjfJlS"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="ADJ myDMX GO Tuotevideo"
                        ></iframe>
                    </div>
                </div>

                {/* Tuotteen kuvaus */}
                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>
                    <div>
                        {/* Puhepainike */}
                        <div className="flex flex-row items-center">
                            <h1 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none">
                                ADJ myDMX GO langaton DMX-ohjain
                            </h1>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text} />
                            </div> 
                        </div>

                        <br />

                        <div className="flex flex-row items-center">
                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                                <strong>ADJ myDMX GO</strong> on vallankumouksellinen uusi valaistuksen ohjausjärjestelmä, joka on erittäin tehokas ja uskomattoman helppokäyttöinen.
                                <br /><br />Mydmx GO -sovellus ei vaadi ohjelmointia, mutta sitä voidaan käyttää luomaan upeita synkronoituja valoesityksiä missä tahansa valaisinyhdistelmässä. 
                                <br /><br />Muutamassa sekunnissa voidaan luoda helposti uskomattomia valaistusnäyttöjä, jotka vaativat tuntikausia ohjelmointia perinteisillä ohjausjärjestelmillä.
                                <br /><br />Yli 15 000 profiilin kattavan valaisinkirjaston ansiosta myDMX GO:ta voidaan käyttää kaikentyyppisten DMX-valaistuksen ohjaamiseen mistä tahansa valmistajasta. 
                                <br /><br />Se on ihanteellinen liikkuvien viihdyttäjien käyttöön sekä pieniin yökerhoihin, baareihin ja vapaa-ajan kohteisiin, joissa tarvitaan yksinkertainen ja helppokäyttöinen valaistuksen ohjausjärjestelmä.
                            </p>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text1} />
                            </div>
                        </div>

                        <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">
                            <FacebookShareButton url={"https://spiderlegsproductions.com/lavamonitori-db-fm12-600w"} className="facebook-share-button flex flex-row items-center" aria-label="Jaa Facebookissa">
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>
                            <TwitterShareButton url={"https://spiderlegsproductions.com/lavamonitori-db-fm12-600w"} className="facebook-share-button flex flex-row items-center " aria-label="Jaa Twitterissä">
                                <XIcon size={32} round />
                            </TwitterShareButton>
                            <WhatsappShareButton url={"https://spiderlegsproductions.com/lavamonitori-db-fm12-600w"} className="facebook-share-button flex flex-row items-center " aria-label="Jaa WhatsAppissa">
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                            <LinkedinShareButton url={"https://spiderlegsproductions.com/lavamonitori-db-fm12-600w"} className="facebook-share-button flex flex-row items-center " aria-label="Jaa LinkedInissä">
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>
                            <EmailShareButton url={"https://spiderlegsproductions.com/lavamonitori-db-fm12-600w"} className="facebook-share-button flex flex-row items-center " aria-label="Jaa sähköpostilla">
                                <EmailIcon size={32} round />
                            </EmailShareButton>
                        </div>

                        {/* Puhepainike */}
                        <div className="flex flex-row items-center">
                            <h2 title="Hinta" className='font-customFont1 text-text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'>
                                Alk. 50€ / vrk
                            </h2>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text2} />
                            </div>
                        </div>

                        {/* Saatavuus-painike */}
                        <div className='flex flex-row items-center gap-12 pt-5'>
                            <Link to="/otayhteytta" onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'auto' }); }}>
                                <button 
                                    title="Tiedustele saatavuutta" 
                                    className="h-12 w-60 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer"
                                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                    aria-label="Tiedustele tuotteen saatavuutta"
                                >
                                    Tiedustele saatavuutta
                                </button>
                            </Link>
                        </div>

                        {/* Tuotteen logo */}
                        <div className='flex flex-wrap place-items-start pt-5'>
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                <a href="https://www.adj.eu/" aria-label="Siirry ADJ:n verkkosivuille" title="Vieraile ADJ:n sivuilla">
                                    <img 
                                        src={ADJ} 
                                        alt="ADJ logo" 
                                        loading="lazy" 
                                        className='w-full h-full object-contain rounded-xl'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ADJmyDMX;
