import { useState } from 'react';
import { Link } from "react-router-dom";
import TextToSpeech from '../../TextToSpeech/textToSpeech';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton 
} from 'react-share';

import { 
    FacebookIcon,
    XIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

const text =
"Multiefekti ADJ Stinger kaksi. Valoshow yhdellä laitteella!";

const text1 =
"Täydellinen valoshow bileisiin: Tämä monipuolinen valo yhdistää moonflowerin, strobon ja laserin yhteen tehokkaaseen pakettiin. Sadat terävät valonsäteet täyttävät tanssilattian, laserit piirtävät upeita kuvioita ja strobo luo vauhdikasta tunnelmaa. Kaikki tämä yhdellä laitteella!";

const text2 =
"DMX-ohjaus: Stinger kaksi on DMX-ohjattava, joten voit helposti integroida sen valaistuksen ohjausjärjestelmääsi tarvittaessa.";

const text3 =
"Sopii jokaiseen tilaan: Stinger kaksi on kompakti ja kevyt, joten se sopii erinomaisesti kaikenkokoisiin tiloihin. Se on helppo asentaa ja käyttää kaukosäätimen avulla.";

const text4 =
"Tekniset tiedot. Mitat: kolmesataaneljä kertaa kaksisataakolmekymmentä pilkku viisi kertaa yhdeksänkymmentäseitsemän pilkku viisi millimetriä, Paino: 5 kiloa, Kaukosäädin, Kantolaukku, Vuokraa tämä monipuolinen valo tapahtumaasi piristämään tunnelmaa!";

const text5 =
"Hinta. Alkaen viisikymmentä euroa vuorokausi. Tiedustele saatavuutta painamalla alla olevaa nappia.";

// Images
import ADJ from '../../assets/ADJ_logo.webp';
import img1 from '../../assets/Multiefekti_ADJ_Stinger_II.webp';
import img2 from '../../assets/Multiefekti_ADJ_Stinger_II_Back.webp';

const ADJStinger2 = () => {
    const [activeImg, setActiveImage] = useState(img1);

    return (
        <>
            {/* Back button */}
            <button 
                onClick={() => { window.history.back(); }} 
                className="h-12 w-32 dark:bg-violet bg-primary dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out"
            >
                Takaisin
            </button>

            {/* Tuotteen kuvat */}
            <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>
                <div className='flex flex-col gap-5'>
                    <img 
                        src={activeImg} 
                        alt='Multiefekti ADJ Stinger II' 
                        title="Multiefekti ADJ Stinger II" 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'
                    />
                    <div className='flex gap-5 flex-wrap justify-center drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>
                        {/* Updated to use buttons for accessibility */}
                        <button 
                            onClick={() => setActiveImage(img1)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="View Multiefekti ADJ Stinger II Front View"
                        >
                            <img 
                                src={img1} 
                                alt="Multiefekti ADJ Stinger II Front View" 
                                title="Multiefekti ADJ Stinger II" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                        <button 
                            onClick={() => setActiveImage(img2)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="View Multiefekti ADJ Stinger II Back View"
                        >
                            <img 
                                src={img2} 
                                alt="Multiefekti ADJ Stinger II Back View" 
                                title="Multiefekti ADJ Stinger II" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                    </div>

                    {/* Tuotteen videot */}
                    <h2 className='font-customFont2 text-shadow-lg text-center text-darkest dark:text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>Videot</h2>
                    <div className="flex gap-5 flex-wrap justify-center aspect-video">
                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/XCDmUI576hg?si=6o9-dvPRpKIsp0Mk"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Multiefekti ADJ Stinger II Product Video"
                        ></iframe>
                    </div>
                </div>
                
                {/* Tuotteen kuvaus */}
                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>
                    <div>

                        {/* SPEECH */}

                        <div className="flex flex-row items-center">
                            {/* Otsikko */}
                            <h1 className='font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none'>Multiefekti ADJ Stinger II</h1>
                            <div className='speech-title ml-5 flex justify-center'>

                                <TextToSpeech text={text} />

                            </div>    
                            
                        </div>
                            
                        <br />

                        {/* Teksti */}
                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>Stinger II Multiefekti: Valoshow yhdellä laitteella!</p><br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                            
                            <strong>Täydellinen valoshow bileisiin:</strong> Tämä monipuolinen valo yhdistää moonflowerin, strobon ja laserin yhteen tehokkaaseen pakettiin. Sadat terävät valonsäteet täyttävät tanssilattian, laserit piirtävät upeita kuvioita ja strobo luo vauhdikasta tunnelmaa. Kaikki tämä yhdellä laitteella!</p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text1} />
                                
                            </div>

                        </div>
                        
                        <br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'><strong>DMX-ohjaus:</strong> Stinger II on DMX-ohjattava, joten voit helposti integroida sen valaistuksen ohjausjärjestelmääsi tarvittaessa.</p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text2} />
                                
                            </div>

                        </div>
                            
                        <br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'><strong>Sopii jokaiseen tilaan:</strong> Stinger II on kompakti ja kevyt, joten se sopii erinomaisesti kaikenkokoisiin tiloihin. Se on helppo asentaa ja käyttää kaukosäätimen avulla.</p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text3} />
                                
                            </div>

                        </div>
                            
                            
                        <br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>Tekniset tiedot:</p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text4} />

                            </div>

                        </div>

                        <ul className="ominaisuudet list-disc font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal p-4">
                            <li>Mitat: 304 x 230,5 x 97,5 mm</li>
                            <li>Paino: 5 kg</li>
                            <li>Kaukosäädin</li>
                            <li>Kantolaukku</li>
                        </ul>
                        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">Vuokraa tämä monipuolinen valo tapahtumaasi piristämään tunnelmaa!</p>

                        <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">

                            <FacebookShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <XIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <WhatsappIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <LinkedinIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <EmailIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </EmailShareButton>

                        </div>


                        {/* SPEECH */}

                        <div className="flex flex-row items-center">
                            {/* Hinta */}
                            <h2 title="Hinta" className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'>Alk. 50€ / vrk</h2>
                            <div className='speech-title ml-5 flex justify-center'>

                                <TextToSpeech text={text5} />
                                
                            </div>

                        </div>

                        {/* Kysele saatavuutta button */}
                        <div className='flex flex-row items-center gap-12 pt-5'>
                            <Link to="/kontakti" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label="Tiedustele saatavuutta">
                                <button 
                                    title="Tiedustele saatavuutta" 
                                    className="h-12 w-60 
                                        dark:bg-primary 
                                        dark:hover:border-2
                                        dark:hover:opacity-90
                                        dark:border-white
                                        dark:text-darkest 

                                        bg-darkest 
                                        hover:border-2
                                        hover:border-opacity-90
                                        hover:opacity-90
                                        border-linkcolhover
                                        text-primary 
                                        
                                        font-customFont1 
                                        font-bold 
                                        text-1xl 
                                        overflow-hidden 
                                        transform hover:scale-x-110 
                                        hover:scale-y-105 transition 
                                        duration-300 ease-in
                                        items-center 
                                        rounded-full 
                                        cursor-pointer" 
                                    
                                    style={{
                                        boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)"
                                    }}
                                >
                                    Tiedustele saatavuutta
                                </button>
                            </Link>
                        </div>

                        <div className='flex flex-wrap place-items-start pt-5'>
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                {/* Tuotteen logo */}
                                <a href="https://www.adj.eu/" aria-label="Visit ADJ's website">
                                    <img 
                                        src={ADJ} 
                                        alt="ADJ logo" 
                                        loading="lazy" 
                                        title="Vieraile ADJ:n sivuilla."
                                        className='w-full h-full object-contain rounded-xl'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ADJStinger2;
