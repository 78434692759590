import { useRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import Footer from '../../components/Footer/Footer';
import headerbackground from '../../assets/kontakti_header.webp';
import ReCAPTCHA from "react-google-recaptcha";
import TextToSpeech from '../../TextToSpeech/textToSpeech';
import ContactPageHelmetComponent from './ContactPageHelmetComponent';

// Hae nykyinen päivämäärä
const today = new Date();

// Päivämäärän merkkijono muodossa YYYY-MM-DD
const formattedDate = today.toISOString().substr(0, 10);

const text =
  "Ota yhteyttä";

const text1 =
  "Täytä oheinen lomake ja valitse, Äänentoistopaketti tai laite, jonka vuokraamisesta olet kiinnostunut. Vuokrauksen ajankohta. Lähetä lomake, palaamme asiaan heti viestin saatuamme.";

const text2 =
  "Saat tiedon laitteiden saatavuudesta ja varmistamme, että tapahtumaasi on valittavissa oikeanlainen äänentoisto.";

const text3 =
  "Tarvitsetko apua? Autamme mielellämme sinua valitsemaan tapahtumaan sopivan äänentoistopaketin tai laitteen.";

const text4 =
  "Ota meihin yhteyttä. Sähköpostitse, info@spiderlegsproductions.com. Puhelimitse: + 358 40 623 41 89. Yhteistyöllä teemme tapahtumastasi ikimuistoisen!";

const ContactForm = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formError, setFormError] = useState(null);
  const form = useRef(null);

  function handleChange(shakeAndbake) {
    console.log(shakeAndbake);
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_h4xqafa",
        "template_s3urnjd",
        form.current,
        "XQMrkTGxtesOUrCdz"
      )
      .then(
        (result) => {
          console.log(result.text);
          setFormSubmitted(true);
        },
        (error) => {
          console.log(error.text);
          setFormError(true);
        }
      );
  };

  useEffect(() => {
    if (formSubmitted && form.current) {
      setTimeout(() => {
        form.current.reset();
        setFormSubmitted(false);
      }, 3000);
    }
  }, [formSubmitted]);

  return (
    <div>
    <ContactPageHelmetComponent />
      <div className="relative h-screen flex items-center justify-center">
        {/* Kuva */}
        <div className="absolute inset-0">
          <img
            src={headerbackground}
            title="kontakti header"
            alt="Kontakti-header"
            className="absolute inset-0 w-full h-full object-cover opacity-85"
          />
        </div>
        {/* Otsikko */}
        <div data-aos="fade-up" className="z-30 text-center bg-black w-full bg-opacity-75">
          <h1 className="font-customFont2 text-shadow-lg text-primary dark:text-primary text-6xl font-normal leading-tight">
            {text}
          </h1>
        </div>
      </div>

      <div className="grid grid-cols-1 xxxsm:grid-cols-1 xxsm:grid-cols-1 bv9900:grid-cols-1 xsm:grid-cols-1 sm:grid-cols-1 md:grid-cols-2 dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75">
        <div id="form-container" className="order-1 p-5 sm:col-start-1">
          <div className="overflow-hidden" title="Yhteydenottolomake">
            <motion.h2
              initial={{ y: "200%" }}
              transition={{
                ease: [0.455, 0.03, 0.515, 0.955],
                duration: 1,
              }}
              animate={{ y: 0 }}
              className="font-customFont2 text-shadow-lg text-darkest dark:text-primary text-3xl sm:text-4xl md:text-4xl lg:text-5xl xl:text-6xl font-bold text-center"
            >
              Yhteydenottolomake
            </motion.h2>
          </div>
          {formError && (
            <motion.div
              transition={{ duration: 0.5, delay: 0.5 }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
            >
              <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
                Valitettavasti tapahtui jokin virhe! Yritä uudelleen myöhemmin. Jos virhe jatkuu, ota yhteyttä asiakaspalveluumme osoitteeseen{" "}
                <strong>info@spiderlegsproductions.com</strong> tai puhelimitse{" "}
                <strong> 358 40 623 41 89. Kiitos.</strong>
              </p>
            </motion.div>
          )}
          <motion.div
            transition={{ duration: 0.5, delay: 0.7 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="py-5"
          >
            {formSubmitted ? (
              <motion.div
                transition={{ duration: 0.5, delay: 0.5 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
              >
            
                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal lg:mt-40 mt-20 mb-20">
                  <p>
                    <strong>Kiitos viestistäsi!</strong> Olemme saaneet viestisi ja käsittelemme sitä parhaillaan. Palaamme asiaan{" "}
                    <strong>mahdollisimman pian</strong>, viimeistään kuitenkin 24 tunnin kuluessa.
                  </p>
                </p>
              </motion.div>
            ) : (
              <form method="POST" action="" ref={form} onSubmit={sendEmail}>
                <div className="mx-auto rounded-xl max-w-xl font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-lg sm:text-xl md:text-lg lg:text-2xl xl:text-2xl font-bold">
                  <div className="grid grid-cols-1 gap-6 label ">
                    <div className="overflow-hidden text-darkest dark:text-white">
                      <label htmlFor="name">Nimi</label>
                      <input
                        id="name"
                        type="text"
                        name="user_name"
                        required="required"
                        placeholder="Nimesi"
                        className="pl-4 appearance-none bg-white border-none placeholder-gray- leading-tight focus:outline-none rounded-full p-4 w-full h-10"
                      />
                    </div>
                    <div className="overflow-hidden text-darkest dark:text-white">
                      <label htmlFor="email">Sähköposti</label>
                      <input
                        id="email"
                        type="email"
                        name="user_email"
                        placeholder="Sähköposti"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        required="required"
                        className="pl-4 appearance-none bg-white border-none placeholder-gray- leading-tight focus:outline-none rounded-full p-4 w-full h-10"
                      />
                    </div>
                    <div className="overflow-hidden text-darkest dark:text-primary">
                      <label htmlFor="phone">Puhelin</label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        placeholder="Puhelin"
                        required="required"
                        className="pl-4 appearance-none bg-white border-none placeholder-gray- leading-tight focus:outline-none rounded-full p-4 w-full h-10"
                      />
                    </div>
                    <div className="overflow-hidden text-darkest dark:text-primary">
                      <label htmlFor="place">Tapahtumapaikka</label>
                      <input
                        id="place"
                        name="place"
                        type="text"
                        placeholder="Tapahtumapaikka"
                        required="required"
                        className="pl-4 appearance-none bg-white border-none leading-tight focus:outline-none rounded-full p-4 w-full h-10"
                      />
                    </div>

                    <div className="flex items-center">
                      <label className="date-label font-customFont1" htmlFor="date">
                        Valitse ajankohta:
                      </label>
                      <div className="flex-grow" />
                      <input className="date-input rounded-full h-10 px-2 text-darkest dark:text-darkest" type="date" name="date" id="date" defaultValue={formattedDate} />
                    </div>

                    <label className="text-gray-400 text-left text-shadow-lg text-lg sm:text-xl md:text-lg lg:text-2xl xl:text-2xl font-normal">
                      <h2
                        className="font-customFont2 dark:text-primary text-darkest text-shadow-lg text-left text-3xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-normal"
                        title="Äänentoistopaketit"
                      >
                      <hr className="text-primary pb-5"></hr>
                        Äänentoistopaketit
                      </h2>
                      <select className="w-full p-2 dark:bg-white border-gray-300 border rounded-full text-darkest dark:text-darkest" name="aanentoisto" defaultValue="">
                        <option value="" disabled hidden>Valitse äänentoistopaketti</option>
                        <option value="Bilekaiutin JBL Partybox 300 + JBL langaton mikrofonijärjestelmä">240W JBL bilekaiutin langattomilla mikrofoneilla</option>
                        <option value="JBL EON 625W äänentoistopaketti mikserillä">625W äänentoistopaketti</option>
                        <option value="Mackie RCF 300W äänentoistopaketti mikserillä">300W äänentoistopaketti</option>
                        <option value="Mackie DLM12 2000W äänentoistopaketti mikserillä">2000W äänentoistopaketti</option>
                        <option value="Monitori FM12 600W">Monitori FM12 600W</option>
                        <option value="Mikseri Soundcraft FX 16">Mikseri Soundcraft FX 16</option>
                      </select>
                    </label>

                    <label className="text-gray-400 text-left text-shadow-lg text-lg sm:text-xl md:text-lg lg:text-2xl xl:text-2xl font-normal ">
                      <h2
                        className="font-customFont2 dark:text-primary text-darkest text-shadow-lg text-left text-3xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-normal"
                        title="Esitystekniikka"
                      >
                        Esitystekniikka
                      </h2>
                      <select className="w-full p-2 dark:bg-white border-gray-300 border rounded-full text-darkest dark:text-darkest" name="esitystekniikka" defaultValue="">
                        <option value="" disabled hidden>Valitse esitystekniikka</option>
                        <option value="Multiefekti ADJ Stinger II">Multiefekti ADJ Stinger II</option>
                        <option value="Savukone ADJ Volcano">Savukone ADJ Volcano</option>
                        <option value="LED-valot Chauvet 56-24C">LED-valot Chauvet 56-24C</option>
                        <option value="ADJ mydmx GO">ADJ mydmx GO-ohjain</option>
                        <option value="Valosetti">Savukone + kaikki valot DMX-ohjaimella</option>
                      </select>
                    </label>

                     {/* Kameran vuokrapaketit */}
                     <label className="text-gray-400 text-left text-shadow-lg text-lg sm:text-xl md:text-lg lg:text-2xl xl:text-2xl font-normal ">
                      <h2
                        className="font-customFont2 dark:text-primary text-darkest text-shadow-lg text-left text-3xl sm:text-3xl md:text-3xl lg:text-4xl xl:text-4xl font-normal"
                        title="Kameran vuokrapaketit"
                      >
                        Kameran vuokrapaketit
                      </h2>
                      <select className="w-full p-2 dark:bg-white border-gray-300 border rounded-full text-darkest dark:text-darkest" name="kamerapaketti" defaultValue="">
                        <option value="" disabled hidden>Valitse kameran vuokrapaketti</option>
                        <option value="Peruspaketti (Kamera + 20 kuvan valokuvapaperi)">Peruspaketti (Kamera + 20 kuvan valokuvapaperi) - 80€</option>
                        <option value="Keskipaketti (Kamera + 50 kuvan valokuvapaperi)">Keskipaketti (Kamera + 50 kuvan valokuvapaperi) - 140€</option>
                        <option value="Premium-paketti (Kamera + 100 kuvan valokuvapaperi)">Premium-paketti (Kamera + 100 kuvan valokuvapaperi) - 200€</option>
                      </select>
                    </label>

                    <label htmlFor="message">Viesti</label>
                      <textarea
                        id="message"
                        name="message"
                        rows="5"
                        required="required"
                        placeholder="Kirjoita viestisi tai kysymyksesi tähän.."
                        className="appearance-none w-full leading-tight focus:outline-none bg-cool-darker text-darkest text-left text-lg sm:text-xl md:text-lg lg:text-2xl xl:text-2xl font-normal rounded-xl p-2"
                        title="Viestisi"
                      />
                    <ReCAPTCHA sitekey="6LcVvMUpAAAAAN1-ERubrqfEf0ykwd_209t02iuo" onChange={handleChange} />
                    <div className="text-center dark:bg-primary 
                      dark:hover:border-2
                      dark:hover:opacity-90
                    dark:border-white
                    

                    bg-darkest 
                      hover:border-2
                      hover:border-opacity-90
                      hover:opacity-90
                    border-linkcolhover
                      overflow-hidden 
                      transform hover:scale-x-105 
                      transition 
                      duration-500 ease-in
                      items-center 
                      rounded-full 
                      cursor-pointer" 

                      alt="Kysy lomakkeella nappi"
                      style={{
                      boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)"}}>
                      <motion.button
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        type="submit"
                        className="uppercase text-sm font-bold tracking-wide text-darkest dark:text-primary p-3 rounded-lg w-full dark:hover:border-1 dark:border-primary border-darkest"
                      >
                        <p title="Lähetä" className="text-primary  font-bold font-customfont1 dark:text-darkest text-shadow-lg">
                          Lähetä
                        </p>
                      </motion.button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </motion.div>
        </div>

        <div className="space-y-4 order-2 sm:order2 md:order-2 p-5">
          <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
            <strong>Äänentoiston vuokraus ei ole koskaan ollut näin helppoa ja vaivatonta!</strong>
          </p>

          {/* SPEECH */}

          <div className="flex flex-row items-center">
            <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal underline">
              Täytä oheinen lomake ja valitse:
            </p>
            <div className="speech-title ml-5 flex justify-center">
              <TextToSpeech text={text1} />
            </div>
          </div>

          <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
            &#8226; Äänentoistopaketti tai laite, jonka vuokraamisesta olet kiinnostunut<br />
            &#8226; Vuokrauksen ajankohta<br /><br />
            Lähetä lomake, palaamme asiaan heti viestin saatuamme.
          </p>

          {/* SPEECH */}

          <div className="flex flex-row items-center">
            <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
              Saat tiedon laitteiden saatavuudesta ja varmistamme, että tapahtumaasi on valittavissa oikeanlainen äänentoisto.
            </p>
            <div className="speech-title ml-5 flex justify-center">
              <TextToSpeech text={text2} />
            </div>
          </div>

          <br />

          <hr className="text-primary"></hr>

          {/* SPEECH */}

          <div className="flex flex-row items-center">
            <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
              <strong>Tarvitsetko apua?</strong>
            </p>
            <div className="speech-title ml-5 flex justify-center">
              <TextToSpeech text={text3} />
            </div>
          </div>

          <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal">
            Ota meihin yhteyttä:
          </p>

          <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
            Sähköpostitse:
          </p>
          <a href="mailto:info@spiderlegsproductions.com" title="Lähetä sähköpostia">
            <p className="underline font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl">
            info@spiderlegsproductions.com
            </p>
          </a>
          <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
            Puhelimitse:
          </p>
          <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl">
            <strong>+ 358 44 351 92 65</strong>
          </p>

          <hr className="text-primary"></hr>

          {/* SPEECH */}

          <div className="flex flex-row items-center">
            <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold">
              <strong>Yhteistyöllä teemme tapahtumastasi ikimuistoisen!</strong>
            </p>
            <div className="speech-title ml-5 flex justify-center">
              <TextToSpeech text={text4} />
            </div>
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default ContactForm;
