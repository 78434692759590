import { Link } from 'react-router-dom';
import OtherRental from "../../components/OtherRentals/OtherRental.json";
import TextToSpeech from '../../TextToSpeech/textToSpeech';

const text = "Tähän osioon on koottu muita tuotteita tai palveluita, joita myymme tai tarjoamme vuokralle. Esittelyssä ensimmäisenä hauska Fujifilm Instax Mini-12 -kompaktikamera.";

const OtherRentals = () => {
  return (
    <div id="muut-vuokrattavat-tuotteet" className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 pb-10 pt-7 pl-5 pr-5">
      <div className="container">
        <div className="flex flex-row">
          <h2 className="text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight text-left font-customFont2 text-darkest dark:text-primary text-shadow-lg">
            MUUT VUOKRATTAVAT TUOTTEET
          </h2>

          <div className='speech-title ml-5 flex justify-center'>
            <TextToSpeech text={text} />
          </div> 
        </div>

        <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal pb-10">
          Tähän osioon on koottu muita tuotteita tai palveluita, joita myymme tai tarjoamme vuokralle. Esittelyssä ensimmäisenä hauska Fujifilm Instax Mini-12 -kompaktikamera.
        </p>

        <div>
          <div className="grid grid-cols-1 xxsm:grid-cols-2 xsm:grid-cols-2 sm:grid-cols-3 md:grid-cols-3 mdd:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-8">
            {OtherRental.map((item) => (
              <div key={item.name} className="bg-white bg-opacity-50 hover:bg-lightbg hover:bg-opacity-60 rounded-3xl relative group p-5">
                <TextToSpeech text={item.text} />
                <div className="w-full h-[150px] pt-5">
                  <Link to={item.link} title={`Katso lisätiedot ${item.name}`} onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label={`Linkki: ${item.name}`}>
                    <img src={item.image} alt={item.name} title={item.name} loading="lazy" className="w-full h-[150px] object-contain sm:translate-x-0 group-hover:scale-125 transition duration-700 hover:rotate-12 cursor-pointer drop-shadow-[3px_3px_5px_rgba(0,0,0,0.80)]" />
                  </Link>
                </div>
                <div className="grid grid-cols-1 place-items-center pt-10">
                  <h3 className="font-customFont1 text-shadow-lg text-center text-darkest dark:text-primary xxxsm:text-lg xxsm:text-lg xsm:text-lg sm:text-lg md:text-lg mdd:text-lg lg:text-gl xl:text-lg font-normal" title={item.name}>
                    {item.name}
                  </h3>
                  <div className="font-customFont1 text-center text-darkest dark:text-primary text-shadow-lg font-normal">
                    <p className="specs font-customFont1 text-center text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-3xl md:text-3xl lg:text-4xl font-normal" title="Hinta">
                      {item.price} € / 24h
                    </p>
                    <Link to={item.link} title={`Katso lisätiedot ${item.name}`} onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label={`Lisätiedot ${item.name}`}>
                      <button className="h-12 w-28 mt-2 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest rounded-full mb-5 shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out opacity-75" title={`Katso lisätiedot ${item.name}`}>
                        Lisätiedot
                      </button>
                    </Link>
                  </div>
                  <div className="grid place-content-center">
                    <div className="soundrental-button">
                      <Link to="/otayhteytta" title="Ota yhteyttä" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label="Ota yhteyttä kysy saatavuutta">
                        <button className="h-12 w-40 dark:bg-violet bg-lightbg dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest items-center rounded-full  mb-6 shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-out font-customFont1 text-center text-shadow-lg font-light" title="Ota yhteyttä!">
                          Kysy saatavuutta
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))} 
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtherRentals;
