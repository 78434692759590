import { useState } from 'react';
import { Link } from "react-router-dom";
import TextToSpeech from '../../TextToSpeech/textToSpeech';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton 
} from 'react-share';

import { 
    FacebookIcon,
    XIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

// Tekstit
const text =
  "JBL EON kuusisataakaksikymmentäviisi wattinen äänentoistopaketti mikserillä. Nosta tapahtumasi uudelle tasolle tällä tehokkaalla ja kompaktilla setillä!";

const text1 =
  "Tämä on toiseksi isoin settimme mikserillä, ja se sopii erinomaisesti seuraaviin käyttötarkoituksiin. JBL:än laadukkaat kaiuttimet tarjoavat erinomaisen äänenlaadun. Paketissa mukana monikäyttöinen Mackien ProFX10v3 -mikseri. Mikserissä on erinomaiset liitännät musiikintoistoon, mikrofoneille ja instrumenteille, ja se toimii myös äänikorttina. Voit toistaa musiikkia suoraan tietokoneesta USB-johdon tai audiokaapelin kautta, kännykästä tai muusta laitteesta.";

const text2 =
  "Tässä vielä muutama syy, miksi sinun pitäisi valita tämä paketti tapahtumaasi. Monipuolisuus. Paketti sopii monenlaisiin tapahtumiin, kuten häihin, pikkujouluihin, yritystilaisuuksiin ja live-musiikkiesityksiin. Helppo kuljetttaa. Kaiuttimet lumpsahtavat kiltisti kestäviin kantokahvallisiin suojalaukkuihinsa ja mikseri omaan olkalaukkuun johtoineen. Koko komeus mahtuu myös helposti henkilöautoon. Ota yhteyttä ja varaa tämä huippupaketti tapahtumaasi!";

const text3 =
  "Hinta. Alkaen satakaksikymmentäeuroa vuorokaudessa. Tiedustele saatavuutta painamalla alla olevaa nappia.";

// Kuvien importointi
import JBL from '../../assets/JBL_logo.webp';
import JBL625WImage from '../../assets/JBLEON_515_625W.webp.webp';
import MackieMixer from '../../assets/Mikseri Mackie ProFX10v3.webp';

const JBL625W = () => {
    const [activeImg, setActiveImage] = useState(JBL625WImage);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    return (
        <>
            {/* Takaisin-painike */}
            <button 
                onClick={() => { window.history.back(); }} 
                className="h-12 w-32 dark:bg-violet bg-primary dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out"
                aria-label="Palaa edelliselle sivulle"
            >
                Takaisin
            </button>

            {/* Tuotteen kuvat */}
            <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>
                <div className='flex flex-col gap-5'> 

                    {/* Suuri kuva */}
                    <img 
                        src={activeImg} 
                        alt='JBL EON 625W äänentoistopaketti' 
                        title="JBL EON 625W äänentoistopaketti" 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]' 
                    />

                    {/* Pienet kuvat */}
                    <div className='flex flex-row justify-center h-24 drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>
                        {/* JBL EON 625W -kuva */}
                        <button 
                            onClick={() => handleImageClick(JBL625WImage)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-1 border-0 bg-transparent focus:outline-none'
                            aria-label="Näytä JBL EON 625W äänentoistopaketti"
                        >
                            <img 
                                src={JBL625WImage} 
                                alt="JBL EON 625W äänentoistopaketti" 
                                title="JBL EON 625W äänentoistopaketti" 
                                className='w-full h-full object-cover rounded-md' 
                            />
                        </button>

                        {/* Mackie ProFX10v3 -mikseri */}
                        <button 
                            onClick={() => handleImageClick(MackieMixer)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-1 border-0 bg-transparent focus:outline-none'
                            aria-label="Näytä Mackie ProFX10v3 mikseri"
                        >
                            <img 
                                src={MackieMixer} 
                                alt="Mikseri Mackie ProFX10v3" 
                                title="Mikseri Mackie ProFX10v3" 
                                className='w-full h-full object-cover rounded-md' 
                            />
                        </button>

                    </div>

                    {/* Tuotteen videot */}
                    <h2 className='font-customFont2 text-shadow-lg text-center text-primary dark:text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>
                        Videot
                    </h2>

                    <div className="flex gap-5 flex-wrap justify-center aspect-video">
                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/rTkP4k3twK0?si=ih7ZY1o_M15Q34pJ"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="JBL EON 625W äänentoistopaketti esittelyvideo"
                            aria-label="Katso JBL EON 625W äänentoistopaketti esittelyvideo"
                        ></iframe>
                    </div>

                </div>

                {/* Tuotteen kuvaus */}
                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>
                    <div>

                        {/* Otsikko ja TextToSpeech */}
                        <div className="flex flex-row items-center">
                            <h1 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none">
                                JBL EON 625W äänentoistopaketti mikserillä
                            </h1>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text} />
                            </div> 
                        </div>

                        <br />

                        {/* Teksti ja TextToSpeech */}
                        <div className="flex flex-row items-center">
                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                                Nosta tapahtumasi uudelle tasolle tällä tehokkaalla ja kompaktilla setillä!
                            </p>
                        </div>

                        <br />

                        {/* Teksti ja TextToSpeech */}
                        <div className="flex flex-row items-center">
                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                                Tämä on toiseksi isoin settimme mikserillä, ja se sopii erinomaisesti seuraaviin käyttötarkoituksiin:
                            </p>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text1} />
                            </div>
                        </div>

                        {/* Lista ominaisuuksista */}
                        <ul className="ominaisuudet list-disc font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal p-4">
                            <li><strong>JBL:än</strong> laadukkaat kaiuttimet tarjoavat erinomaisen äänenlaadun.</li>
                            <li><strong>Paketissa mukana monikäyttöinen Mackien ProFX10v3 -mikseri.</strong> Mikserissä on erinomaiset liitännät musiikintoistoon, mikrofoneille ja instrumenteille, ja se toimii myös äänikorttina. Voit toistaa musiikkia suoraan tietokoneesta USB-johdon tai audiokaapelin kautta, kännykästä tai muusta laitteesta.</li>
                        </ul>

                        {/* Lisäsyyt ja TextToSpeech */}
                        <div className="flex flex-row items-center">
                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                                Tässä vielä muutama syy, miksi sinun pitäisi valita tämä paketti tapahtumaasi:
                            </p>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text2} />
                            </div>
                        </div>

                        {/* Lista lisäsyistä */}
                        <ul className="ominaisuudet list-disc font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal p-4">
                            <li><strong>Monipuolisuus:</strong> Paketti sopii monenlaisiin tapahtumiin, kuten häihin, pikkujouluihin, yritystilaisuuksiin ja live-musiikkiesityksiin.</li>
                            <li><strong>Helppo kuljetttaa:</strong> Kaiuttimet lumpsahtavat kiltisti kestäviin kantokahvallisiin suojalaukkuihinsa ja mikseri omaan olkalaukkuun johtoineen. Koko komeus mahtuu myös helposti henkilöautoon.</li>
                        </ul>

                        {/* Lopetusviesti */}
                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                            Ota yhteyttä ja varaa tämä huippupaketti tapahtumaasi!
                        </p>

                        {/* Sosiaalisen median jakopainikkeet */}
                        <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">
                            <FacebookShareButton
                                url={"https://spiderlegsproductions.com/625w-aanentoistopaketti"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={"https://spiderlegsproductions.com/625w-aanentoistopaketti"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <XIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={"https://spiderlegsproductions.com/625w-aanentoistopaketti"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <WhatsappIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={"https://spiderlegsproductions.com/625w-aanentoistopaketti"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <LinkedinIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={"https://spiderlegsproductions.com/625w-aanentoistopaketti"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <EmailIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </EmailShareButton>
                        </div>

                        {/* Hinta ja TextToSpeech */}
                        <div className="flex flex-row items-center">
                            <h2 
                                title="Hinta" 
                                className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'
                            >
                                Alk. 120€ / vrk
                            </h2>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text3} />
                            </div>
                        </div>

                        {/* Kysele saatavuutta -painike */}
                        <div className='flex flex-row items-center gap-12 pt-5'>
                            <Link 
                                to="/otayhteytta" 
                                onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'auto' }); }}
                            >
                                <button 
                                    title="Tiedustele saatavuutta" 
                                    className="h-12 w-60 dark:bg-primary 
                                        dark:hover:border-2
                                        dark:hover:opacity-90
                                        dark:border-white
                                        dark:text-darkest 
                                        bg-darkest 
                                        hover:border-2
                                        hover:border-opacity-90
                                        hover:opacity-90
                                        border-linkcolhover
                                        text-primary 
                                        font-customFont1 
                                        font-bold 
                                        text-1xl 
                                        overflow-hidden 
                                        transform hover:scale-x-110 
                                        hover:scale-y-105 transition 
                                        duration-300 ease-in
                                        items-center 
                                        rounded-full 
                                        cursor-pointer" 
                                    style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)" }}
                                >
                                    Tiedustele saatavuutta
                                </button>
                            </Link>
                        </div>

                        {/* Tuotteen logo */}
                        <div className='flex flex-wrap place-items-start pt-5'>
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                <a href="https://fi.jbl.com/" aria-label="Vieraile JBL:n sivuilla.">
                                    <img 
                                        src={JBL}  
                                        alt="JBL logo" 
                                        loading="lazy" 
                                        title="Vieraile JBL:n sivuilla." 
                                        className='w-full h-full object-contain rounded-xl' 
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default JBL625W;
