import { useState } from 'react';
import { Link } from "react-router-dom";
import TextToSpeech from '../../TextToSpeech/textToSpeech';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton 
} from 'react-share';

import { 
    FacebookIcon,
    XIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

const text =
"Savukone ADJ VF Volcano. Täydellinen savukone tanssilattialle tai lavalle. Kompakti, pystysuoraan ampuva savukone, joka tuo uuden ulottuvuuden savutehosteisiin. Se on varustettu kuusi kertaa kolmen watin RGB-LEDillä, jotka sekoittavat värejä savuun ja luovat upeita, värikkäitä tehosteita.";

const text1 =
"Uusi lämmitysteknologia. ADJ:n lämmitinteknologian uusien edistysaskeleiden ansiosta VF Volcano tuottaa kuivan, tasaisen savupatsaan yli neljän pilkku viiden metrin korkeuteen. Erityinen lämmityselementtirakenne ja öljytyyppinen voimansiirto takaavat, että laite ei tukkeudu."; 

const text2 =
"Tehokas ja luotettava: Savukone on varustettu tehokkaalla italialaisella pumppujärjestelmällä, joka takaa optimaalisen tehon. ADJ:n Electronic Thermo Sensing (ETS) -tekniikka pitää lämpötilan optimaalisena ja takaa lyhyen lämpenemisajan savujen välillä.";

const text3 =
"Hinta. Alkaen neljäkymmentä euroa vuorokausi. Tiedustele saatavuutta painamalla alla olevaa nappia.";

// Images 
import ADJ from '../../assets/ADJ_logo.webp';
import img1 from '../../assets/ADJ_VF_Volcano1.webp';
import img2 from '../../assets/ADJ_VF_Volcano_Back.webp';
import img3 from '../../assets/ADJ_VF_Volcano2.webp';

const ADJVolcano = () => {
    const [activeImg, setActiveImage] = useState(img1);

    return (
        <>
            {/* Back button */}
            <button 
                onClick={() => { window.history.back(); }} 
                className="h-12 w-32 dark:bg-violet bg-primary dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out"
                aria-label="Go Back"
            >
                Takaisin
            </button>

            {/* Tuotteen kuvat */}
            <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>
                <div className='flex flex-col gap-5'>
                    <img 
                        src={activeImg} 
                        alt='Savukone ADJ VF Volcano' 
                        title="Savukone ADJ VF Volcano" 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'
                    />
                    <div className='flex flex-row justify-center h-24 drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>
                        {/* Updated to use buttons for accessibility */}
                        <button 
                            onClick={() => setActiveImage(img1)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="View Savukone ADJ VF Volcano Front View"
                        >
                            <img 
                                src={img1} 
                                alt="Savukone ADJ VF Volcano Front View" 
                                title="Savukone ADJ VF Volcano" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                        <button 
                            onClick={() => setActiveImage(img2)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="View Savukone ADJ VF Volcano Panel View"
                        >
                            <img 
                                src={img2} 
                                alt="Savukone ADJ VF Volcano Panel View" 
                                title="Savukone ADJ VF Volcano paneeli" 
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                        <button 
                            onClick={() => setActiveImage(img3)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="View Savukone ADJ VF Volcano Remote Control View"
                        >
                            <img 
                                src={img3} 
                                alt="Savukone ADJ VF Volcano Remote Control View" 
                                title="Savukone ADJ VF Volcano + kaukosäädin"  
                                className='w-full h-full object-cover rounded-md'
                            />
                        </button>
                    </div>

                    {/* Tuotteen videot */}
                    <h2 className='font-customFont2 text-shadow-lg text-center text-primary dark:text-primary p-10 text-3xl sm:text-5xl md:text-5xl lg:text-5xl xl:text-5xl font-bold'>Videot</h2>
                    <div className="flex gap-5 flex-wrap justify-center aspect-video">
                        <iframe
                            className="aspect-auto"
                            src="https://www.youtube.com/embed/jYk12Dg-BzE?si=sVFfpZ9g8I4-FoFu"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Savukone ADJ VF Volcano Product Video"
                        ></iframe>
                    </div>
                </div>
                
                {/* Tuotteen kuvaus */}
                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>
                    <div>

                        {/* SPEECH */}

                        <div className="flex flex-row items-center">
                            {/* Otsikko */}
                            <h1 className='font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none'>Savukone ADJ VF Volcano</h1>
                            <div className='speech-title ml-5 flex justify-center'>

                                <TextToSpeech text={text} />

                            </div>    
                            
                        </div>
                            
                        <br />

                        {/* Teksti */}
                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                            <strong>Täydellinen savukone tanssilattialle tai lavalle.</strong> Kompakti, pystysuoraan ampuva savukone, joka tuo uuden ulottuvuuden savutehosteisiin. Se on varustettu 6 x 3 watin RGB-LEDillä, jotka sekoittavat värejä savuun ja luovat upeita, värikkäitä tehosteita.
                        </p>
                        
                        <br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                                <strong>Uusi lämmitysteknologia:</strong> ADJ:n lämmitinteknologian uusien edistysaskeleiden ansiosta VF Volcano tuottaa kuivan, tasaisen savupatsaan yli 4,5 metrin korkeuteen. Erityinen lämmityselementtirakenne ja öljytyyppinen voimansiirto takaavat, että laite ei tukkeudu.
                            </p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text1} />
                                
                            </div>

                        </div>
                            
                        <br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                                <strong>Tehokas ja luotettava:</strong> VF Volcano on varustettu tehokkaalla italialaisella pumppujärjestelmällä, joka takaa optimaalisen tehon. ADJ:n Electronic Thermo Sensing (ETS) -tekniikka pitää lämpötilan optimaalisena ja takaa lyhyen lämpenemisajan savujen välillä.
                            </p>
                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text2} />
                                
                            </div>

                        </div>
                            
                        <div className="flex flex-row py-5 gap-2" title="Jaa tämä sivu">

                            <FacebookShareButton
                                url={"https://spiderlegsproductions.com/savukone-adj-volcano"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={"https://spiderlegsproductions.com/savukone-adj-volcano"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <XIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={"https://spiderlegsproductions.com/savukone-adj-volcano"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <WhatsappIcon size={32} round />
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={"https://spiderlegsproductions.com/savukone-adj-volcano"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <LinkedinIcon size={32} round />
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={"https://spiderlegsproductions.com/savukone-adj-volcano"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <EmailIcon size={32} round />
                            </EmailShareButton>

                        </div>


                        {/* SPEECH */}

                        <div className="flex flex-row items-center">
                            {/* Hinta */}
                            <h2 title="Hinta" className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'>Alk. 40€ / vrk</h2>

                            <div className='speech-title ml-5 flex justify-center'>

                                <TextToSpeech text={text3} />
                                
                            </div>

                        </div>
                        {/* Kysele saatavuutta button */}
                        <div className='flex flex-row items-center gap-12 pt-5'>
                            <Link to="/otayhteytta" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label="Tiedustele saatavuutta">
                                <button 
                                    title="Tiedustele saatavuutta" 
                                    className="h-12 w-60 dark:bg-primary 
                          dark:hover:border-2
                          dark:hover:opacity-90
                        dark:border-white
                        dark:text-darkest 

                        bg-darkest 
                          hover:border-2
                          hover:border-opacity-90
                          hover:opacity-90
                        border-linkcolhover
                        text-primary 
                        
                          font-customFont1 
                          font-bold 
                          text-1xl 
                          overflow-hidden 
                          transform hover:scale-x-110 
                          hover:scale-y-105 transition 
                          duration-300 ease-in
                          items-center 
                          rounded-full 
                          cursor-pointer" 
                    
                          style={{
                              boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)"
                          }}>
                                    Tiedustele saatavuutta
                                </button>
                            </Link>
                        </div>
                        <div className='flex flex-wrap place-items-start pt-5'>
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                {/* Tuotteen logo */}
                                <a href="https://www.adj.eu/" aria-label="Visit ADJ's website">
                                    <img 
                                        src={ADJ} 
                                        alt="ADJ logo" 
                                        loading="lazy" 
                                        title="Vieraile ADJ:n sivuilla."
                                        className='w-full h-full object-contain rounded-xl'
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ADJVolcano;
