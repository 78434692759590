import { Link } from 'react-router-dom';
import TextToSpeech from '../../TextToSpeech/textToSpeech';

// Images
import PartyBox300 from "../../assets/JBL_Partybox_mikit.webp";
import JBLEON from "../../assets/JBLEON_515_625W.webp.webp"; // Corrected path
import Stinger2 from "../../assets/Multiefekti_ADJ_Stinger_II.webp"; // Corrected path
import InstaxMini12 from "../../assets/mini12.webp";

const text =
  "Vuokratuimmat. Tutustu suosituimpiin ja vuokratuimpiin laitteisiimme. Näiden laadukkaiden tuotteiden avulla varmistat onnistuneen tapahtuman, olipa kyseessä bileet, teemabileet tai muu erityistilaisuus. Katso kaikki vuokrattavat klikkaamalla katso kaikki nappia.";

const mostPopular  = [
    {
        id: 1,
        name: "JBL bilekaiutin mikrofoneilla",
        description: "Lisätiedot",
        price: 50,
        image: PartyBox300,
        link: '/bilekaiutin-mikrofoneilla',
        text: 'JBL 300, 240 wattinen bilekaiutin langattomilla mikrofoneilla. Hinta, 50 euroa, vuokra-aika, kaksikymmentäneljätuntia. Katso tarkempi kuvaus painamalla lisätiedot'
    },
    {
        id: 2,
        name: "625W äänentoistopaketti",
        price: 120,
        image: JBLEON,
        description: "Lisätiedot",
        link: '/625w-aanentoistopaketti',
        text: '625 wattinen äänentoistopaketti mikserillä. Hinta, 120 euroa, vuokra-aika, kaksikymmentäneljätuntia. Katso tarkempi kuvaus painamalla lisätiedot'
    },
    {
        id: 3,
        name: "Multiefektivalo ADJ Stinger II",
        price: 50,
        image: Stinger2,
        description: "Lisätiedot",
        link: '/multiefekti-adj-stinger-ii',
        text: 'Tämä on ADJ:n Stinger kaksi multiefekti: Valoshow yhdellä laitteella! Hinta, 50 euroa, vuokra-aika, kaksikymmentäneljätuntia. Katso tarkempi kuvaus painamalla lisätiedot.'
    },
    {
        id: 4,
        name: "Fujifilm Instax Mini-12",
        price: "80",
        category: "Muut palvelut",
        link: "/fujifilm-instax-mini-12",
        image: InstaxMini12,
        text: "Vuokraa Fujifilm Instax Mini-12 -kamera tilaisuuteesi. Sopii täydellisesti juhliin, häihin ja muihin tapahtumiin. Kamera tulostaa välittömästi kuvan ja antaa sinulle fyysisen muiston hetkestä."
    }
];

const MostPopular = () => {
    return (
        <div data-aos="fade-right" className="dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 pb-4 pl-5 pr-5 rounded-3xl cursor-pointer p-5">
            <div className="container pb-10 pl-5 pr-5">

                {/* Sivun otsikko */}
                <div className="flex flex-row items-center">
                    <h2 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-5xl sm:text-6xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-6xl font-bold leading-tight" aria-label="Vuokratuimmat laitteet">
                        VUOKRATUIMMAT
                    </h2>
                    <div className='speech-title ml-5 flex justify-center'>
                        <TextToSpeech text={text} />
                    </div> 
                </div>

                {/* Sivun kuvaus */}
                <p className="font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal pb-10">
                    Tutustu suosituimpiin ja vuokratuimpiin laitteisiimme. Näiden laadukkaiden tuotteiden avulla varmistat onnistuneen tapahtuman, olipa kyseessä bileet, teemabileet tai muu erityistilaisuus. 
                </p>

                {/* Lista vuokrattavista */}
                <div>
                    <div className="grid grid-cols-1 xxsm:grid-cols-2 xsm:grid-cols-2 bv9900:grid-cols-2 sm:grid-cols-2 md:grid-cols-2 mdd:grid-cols-4 lg:grid-cols-4 xl:grid-cols-4 gap-4">
                        {mostPopular.map((data) => (
                            <div key={data.name} data-aos="fade-up" className="bg-white bg-opacity-50 hover:bg-lightbg hover:bg-opacity-60 rounded-3xl relative group p-5 flex flex-col justify-between">
                                <TextToSpeech text={data.text} />
                                {/* Sisääntulo efekti */}
                                <div className="w-full h-[120px]">
                                    <Link to={data.link} title={data.name} onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label={data.name}>
                                        <img src={data.image} alt={data.name} loading="lazy" title={data.name} className="w-full h-[150px] object-contain sm:translate-x-0 transition duration-700 cursor-pointer drop-shadow-[3px_3px_5px_rgba(0,0,0,0.80)]" />
                                    </Link>
                                </div>
                                <div className="flex flex-col justify-between flex-grow">
                                    <div className="grid grid-cols-1 place-items-center pt-10">
                                        {/* Laitteen kuvauksen teksti */}
                                        <h3 className="font-customFont1 text-shadow-lg text-center text-darkest dark:text-primary xxxsm:text-sm xxsm:text-base xsm:text-lg sm:text-xl md:text-2xl mdd:text-1xl lg:text-2xl xl:text-3xl font-normal pb-5" title={data.name}>{data.name}</h3>
                                    </div>
                                    <div className="mt-auto text-center">
                                        <div className="font-customFont1 text-darkest dark:text-primary text-shadow-lg font-normal pb-2">
                                            {/* Laitteiden hinta */}
                                            <p className="specs font-customFont1 text-darkest dark:text-primary text-shadow-lg xxxsm:text-base xxsm:text-lg xsm:text-xl sm:text-2xl md:text-3xl mdd:text-2xl lg:text-4xl xl:text-4xl font-normal"> {data.price} € / 24h</p>
                                        </div>
                                        <div className="flex justify-center">
                                            <Link to={data.link} title="tuotekuvaus" onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}} aria-label={`Katso lisätiedot ${data.name}`}>
                                                <button className="h-12 w-28 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-1xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer" title="Katso lisätiedot">Lisätiedot</button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}       
                    </div>

                    <div className="grid place-content-center mt-10">
                        <Link to="/palvelut" onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'instant' }) } } aria-label="Katso kaikki vuokrattavat laitteet">
                            <button title="Katso kaikki!" className="h-12 w-52 dark:bg-primary dark:hover:border-2 dark:hover:opacity-90 dark:border-white dark:text-darkest bg-darkest hover:border-2 hover:border-opacity-90 hover:opacity-90 border-linkcolhover text-primary font-customFont1 font-bold text-2xl overflow-hidden transform hover:scale-x-110 hover:scale-y-105 transition duration-300 ease-in items-center rounded-full cursor-pointer" style={{ boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)"}}>
                                Katso kaikki!
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};
        
export default MostPopular;
