import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import './index.css';
import './styles.css';

// Valitse root-elementti
const container = document.getElementById('root');
const root = createRoot(container);

// Määrittele sovelluksen pääkomponentti
const Root = () => (
  <React.StrictMode>
    <HelmetProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>
);

// Renderöi pääkomponentti
root.render(<Root />);
