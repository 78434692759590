import { Helmet } from 'react-helmet-async';

function ContactPageHelmetComponent() {
  return (
    <Helmet>
      {/* Page title */}
      <title>Spiderlegs productions | Ota yhteyttä</title>
      
      {/* Meta description */}
      <meta name="description" content="Helppoa ja vaivatonta! Täytä yhteydenottolomakeemme ja olet askeleen lähempänä bileitä! | Kontakti" />
      
      {/* Meta keywords */}
      <meta name="keywords" content="vuokrattava, vuokraus, kontakti, yhteydenottolomake, lomake, laite, paketti, helppo" />
      
      {/* Meta author */}
      <meta name="author" content="Arttu Stenius" />
      
      {/* Canonical link */}
      <link rel='canonical' href='https://spiderlegsproductions.com/otayhteytta' />
      
      {/* Open Graph tags */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Spiderlegs productions | Ota yhteyttä" />
      <meta property="og:description" content="Helppoa ja vaivatonta! Täytä yhteydenottolomakeemme ja olet askeleen lähempänä bileitä! | Ota yhteyttä" />
      <meta property="og:image" content="https://spiderlegsproductions.com/assets/new_logo.webp" />
      <meta property="og:url" content="https://spiderlegsproductions.com/otayhteytta" />
      
      {/* Twitter Card tags */}
      <meta name="twitter:title" content="Spiderlegs productions | Ota yhteyttä" />
      <meta name="twitter:description" content="Helppoa ja vaivatonta! Täytä yhteydenottolomakeemme ja olet askeleen lähempänä bileitä! | Ota yhteyttä" />
      <meta name="twitter:image" content="https://spiderlegsproductions.com/assets/new_logo.webp" />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default ContactPageHelmetComponent;
