import { useState } from 'react';
import { Link } from "react-router-dom";
import TextToSpeech from '../../TextToSpeech/textToSpeech';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton 
} from 'react-share';

import { 
    FacebookIcon,
    XIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

// Tekstit
const text =
  "LED-valot Chauvet 56-24C, kuusi kappaletta. Näillä LED-valoilla saa luotua upeaa tunnelmaa ja näyttävän valoshow juhlatilaan. Nämä monipuoliset valot sopivat täydellisesti tunnelman luomiseen kaikenlaisissa tiloissa.";

const text1 =
  "Valojen ominaisuudet. Ääniohjatut valo-ohjelmat: Voit antaa musiikin ohjata valaistusta ja luoda upeita visuaalisia tehosteita. Manuaalisesti säädettävät värit: Voit valita itse haluamasi värit ja luoda juuri oikeanlaisen tunnelman tapahtumaasi. Tehokkaat LED-valot: kahdeksan punaista, kahdeksan vihreää ja kahdeksan sinistä diodia takaavat kirkkaan ja värikkään valaistuksen. Suuri kirkkaus. Valot ovat erittäin kirkkaat ja ne sopivat myös isojen tilojen valaisemiseen. Alhainen lämmöntuotanto ja energiankulutus. LED-valot ovat energiatehokkaita ja ne eivät kuumene käytössä. Kuljetuslaatikko pyörillä ja vetokahvalla"; 

const text2 =
  "Hinta. Alkaen viisikymmentä euroa vuorokaudessa. Tiedustele saatavuutta painamalla alla olevaa nappia.";

// Kuvien importointi
import Chauvet from '../../assets/chauvet_logo.webp';
import img1 from '../../assets/LED-valot Chauvet 56-24C.webp';
import img2 from '../../assets/LED-valot Chauvet 56-24C2.webp';

const LEDLights = () => {
    const [activeImg, setActiveImage] = useState(img1);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    return (
        <>
            {/* Takaisin-painike */}
            <button 
                onClick={() => { window.history.back(); }} 
                className="h-12 w-32 dark:bg-violet bg-primary dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none"
                aria-label="Palaa edelliselle sivulle"
            >
                Takaisin
            </button>

            {/* Tuotteen kuvat */}
            <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>
                <div className='flex flex-col gap-5'>
                    <img 
                        src={activeImg} 
                        alt='LED-valot Chauvet 56-24C' 
                        title="LED-valot Chauvet 56-24C" 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'
                    />
                    <div className='flex flex-row justify-center h-24 drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>
                        {/* Klikattavat kuvat korvattu button-elementeillä */}
                        <button 
                            onClick={() => handleImageClick(img1)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Näytä LED-valot Chauvet 56-24C"
                        >
                            <img 
                                src={img1} 
                                alt="LED-valot Chauvet 56-24C" 
                                title="LED-valot Chauvet 56-24C" 
                                className='w-full h-full object-cover rounded-md' 
                            />
                        </button>
                        <button 
                            onClick={() => handleImageClick(img2)} 
                            className='w-32 h-32 rounded-md cursor-pointer p-0 border-0 bg-transparent focus:outline-none'
                            aria-label="Näytä LED-valot Chauvet 56-24C ulkona"
                        >
                            <img 
                                src={img2} 
                                alt="LED-valot Chauvet 56-24C ulkona" 
                                title="LED-valot Chauvet 56-24C ulkona" 
                                className='w-full h-full object-cover rounded-md' 
                            />
                        </button>
                    </div>
                </div>
                
                {/* Tuotteen kuvaus */}
                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>
                    <div>

                        {/* SPEECH */}

                        <div className="flex flex-row items-center">
                            {/* Otsikko */}
                            <h1 className='font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none'>
                                LED-valot Chauvet 56-24C 6kpl
                            </h1>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text} />
                            </div>    
                        </div>
                            

                        <br />

                        {/* Teksti */}
                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                            Näillä LED-valoilla saa luotua upeaa tunnelmaa ja näyttävän valoshow juhlatilaan.
                        </p><br />
                        <p className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal'>
                            Nämä monipuoliset valot sopivat täydellisesti tunnelman luomiseen kaikenlaisissa tiloissa.
                        </p><br />

                        {/* SPEECH */}
                        
                        <div className="flex flex-row items-center">
                            <h2 className='font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-bold'>
                                Valojen ominaisuudet:
                            </h2>
                            <div className='speech-title ml-5'>
                                <TextToSpeech text={text1} />
                            </div>
                        </div>

                        <ul className="ominaisuudet list-disc font-customFont1 text-shadow-lg text-left text-darkest dark:text-primary text-xl sm:text-1xl md:text-1xl lg:text-1xl xl:text-2xl 3xl:text-3xl font-normal p-4">
                            <li><strong>Ääniohjatut valo-ohjelmat:</strong> Voit antaa musiikin ohjata valaistusta ja luoda upeita visuaalisia tehosteita.</li>
                            <li><strong>Manuaalisesti säädettävät värit:</strong> Voit valita itse haluamasi värit ja luoda juuri oikeanlaisen tunnelman tapahtumaasi.</li>
                            <li><strong>Tehokkaat LED-valot:</strong> 8 punaista, 8 vihreää ja 8 sinistä diodia takaavat kirkkaan ja värikkään valaistuksen.</li>
                            <li><strong>Suuri kirkkaus:</strong> Valot ovat erittäin kirkkaat ja ne sopivat myös isojen tilojen valaisemiseen.</li>
                            <li><strong>Alhainen lämmöntuotanto ja energiankulutus:</strong> LED-valot ovat energiatehokkaita ja ne eivät kuumene käytössä.</li>
                            <li>Kuljetuslaatikko pyörillä ja vetokahvalla</li>
                        </ul>

                        <div className="flex flex-row py-5 gap-2" title='Jaa tämä sivu'>
                            <FacebookShareButton
                                url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <XIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <WhatsappIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <LinkedinIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={"https://spiderlegsproductions.com/led-valot-chauvet-56-24c"}
                                className="facebook-share-button flex flex-row items-center "
                            >
                                <EmailIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </EmailShareButton>
                        </div>

                        {/* Hinta ja TextToSpeech */}
                        <div className="flex flex-row items-center">
                            <h2 
                                title="Hinta" 
                                className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'
                            >
                                Alk. 50€ / vrk
                            </h2>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text2} />
                            </div>
                        </div>

                        {/* Kysele saatavuutta button */}
                        <div className='flex flex-row items-center gap-12 pt-5'>
                            <Link 
                                to="/otayhteytta" 
                                onClick={() => {window.scrollTo({top:0, left: 0, behavior: 'auto'});}}
                            >
                                <button 
                                    title="Tiedustele saatavuutta" 
                                    className="h-12 w-60 dark:bg-primary 
                                        dark:hover:border-2
                                        dark:hover:opacity-90
                                        dark:border-white
                                        dark:text-darkest 

                                        bg-darkest 
                                        hover:border-2
                                        hover:border-opacity-90
                                        hover:opacity-90
                                        border-linkcolhover
                                        text-primary 

                                        font-customFont1 
                                        font-bold 
                                        text-1xl 
                                        overflow-hidden 
                                        transform hover:scale-x-110 
                                        hover:scale-y-105 transition 
                                        duration-300 ease-in
                                        items-center 
                                        rounded-full 
                                        cursor-pointer focus:outline-none" 

                                    style={{
                                        boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)"
                                    }}
                                >
                                    Tiedustele saatavuutta
                                </button>
                            </Link>
                        </div>
                        <div className='flex flex-wrap place-items-start pt-5'>
                            <div className="bg-darkest/50 grid  dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                {/* Tuotteen logo */}
                                <a href="https://www.chauvetprofessional.com/" aria-label="Vieraile Chauvet:in sivuilla.">
                                    <img 
                                        src={Chauvet}  
                                        alt="Chauvet logo" 
                                        loading="lazy" 
                                        title="Vieraile Chauvet:in sivuilla." 
                                        className='w-full h-full object-contain rounded-xl' 
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default LEDLights;
