// src/components/Navbar/Navbar.jsx

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Navbar.css';
import Dropdown from '../../components/Navbar/Dropdown'; 
import { GiLongLeggedSpider } from "react-icons/gi";
import { HiMenu, HiX } from 'react-icons/hi';
import lightmode from '../../assets/light-mode.webp';
import darkmode from '../../assets/dark-mode.webp';

const Navbar = ({ theme, setTheme }) => {
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 881);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const onMouseEnter = () => {
    if (!isMobile) {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (!isMobile) {
      setDropdown(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 881);
      if (window.innerWidth > 881) {
        setClick(false); // Sulje mobiilimenu, kun ikkuna laajenee
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <nav className='navbar dark:bg-violet'>
      <div className="dark:text-primary navbar-logo font-bold font-customFont2 text-darkest flex items-center justify-center md:justify-start text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl 3xl:text-4xl">
        <Link to="/" className="flex items-center dark:text-primary text-darkest">
          <GiLongLeggedSpider size={40} />
          &nbsp;&nbsp;<span>Spiderlegs productions</span>
        </Link>
        {/* Vaihtoteeman Button */}
        <button
          onClick={() => setTheme(theme === 'light' ? 'dark' : 'light')}
          aria-label="Vaihda teemaa"
          className={`toggle-button focus:outline-none`}
        >
          <img
            src={theme === 'light' ? darkmode : lightmode}
            alt={theme === 'light' ? 'Dark mode' : 'Light mode'}
            title="Vaihda teemaa"
            className={`toggle-icon ${click || theme === 'light' || theme === 'dark' ? 'rotating' : ''}`} 
          />
        </button>
      </div>

      {/* Menu Icon Button */}
      <button 
        aria-label="Avaa tai sulje menu"
        title="Menu" 
        className={`menu-icon dark:text-primary text-darkest ${click ? 'active' : ''}`} 
        onClick={handleClick}
      >
        {click ? <HiX size={40} /> : <HiMenu size={40} />}
      </button>

      <ul className={click ? 'nav-menu active' : 'nav-menu'}>
        <li title="Koti" className='nav-item'>
          <Link to='/' className='nav-links' onClick={closeMobileMenu}>Koti</Link>
        </li>
        <li 
          title="Palvelut" 
          className='nav-item' 
          onMouseEnter={onMouseEnter} 
          onMouseLeave={onMouseLeave}
        >
          <Link to='/palvelut' className='nav-links' onClick={closeMobileMenu}>Palvelut</Link>
          {dropdown && <Dropdown />}
        </li>
        <li title="Meistä" className='nav-item'>
          <Link to='/meista' className='nav-links' onClick={closeMobileMenu}>Meistä</Link>
        </li>
        <li title="UKK" className='nav-item'>
          <Link to='/UKK' className='nav-links' onClick={closeMobileMenu}>UKK</Link>
        </li>
        <li title="Ota yhteyttä" className='nav-item'>
          <Link to='/otayhteytta' className='nav-links' onClick={closeMobileMenu}>Ota yhteyttä</Link>
        </li>
      </ul>
    </nav>
  );
};

// Define prop types
Navbar.propTypes = {
  theme: PropTypes.string.isRequired, // Validate that 'theme' is a required string
  setTheme: PropTypes.func.isRequired, // Validate that 'setTheme' is a required function
};

export default Navbar;
