import { useState } from 'react';
import { Link } from "react-router-dom";
import TextToSpeech from '../../TextToSpeech/textToSpeech';

import { 
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    EmailShareButton 
} from 'react-share';

import { 
    FacebookIcon,
    XIcon,
    WhatsappIcon,
    LinkedinIcon,
    EmailIcon 
} from 'react-share';

const text =
  "Mikseri Soundcraft FX kuusitoista. Studiolaatuinen ääni, olipa kyseessä live-esitys tai studiotyöskentely, Soundcraft Spirit äfäxkuudellatoista";

const text1 =
  "Ominaisuudet. Monipuolinen analoginen neli-väyläinen mikseri, joka tuottaa mainion äänenlaadun sekä live-esityksissä että studiokäytössä. Se tarjoaa laajan valikoiman ominaisuuksia, jotka tekevät musiikin teosta, miksaamisesta ja äänityksestä entistä nautinnollisempaa ja helpompaa.";

const text2 =
  "Lisätietoja. Kuusitoista mikrofoniliitäntätuloa ja yhteensä kaksikymmentäkuusi sisääntuloa. Voit miksata samanaikaisesti useita instrumentteja ja muita äänilähteitä. Kuusi suoraa ulostuloa. Tallenna ääni moniraitoihin studiossa tai lähetä sitä suoraan peeaa-järjestelmään keikoilla. Lexiconin tehosteprosessorin kuusitoista ohjelmaa. Lisää ammattimaisia efektejä kahdella samanaikaisella efektillä. Brittiläinen kolmi-alueinen taajuuskorjain. Muokkaa ääntä tarkasti ja luo haluamasi soundi. Neljä apukanavavahvistusta. Lähetä ääntä monitorointiin, efekteihin tai muihin laitteisiin. Räkkiasennettu";

const text3 =
  "Hinta. Alkaen viisikymmentä euroa vuorokausi. Tiedustele saatavuutta painamalla alla olevaa nappia.";

// Images
import Soundcraftlogo from '../../assets/soundcraft_logo.webp';
import img1 from '../../assets/Soundcraft Spirit FX 16.webp';
import img2 from '../../assets/Soundcraft Spirit FX 16 back.webp';

const SoundcraftFX16 = () => {
    const [activeImg, setActiveImage] = useState(img1);

    const handleImageClick = (image) => {
        setActiveImage(image);
    };

    return (
        <>
            {/* Back button */}
            <button 
                onClick={() => { window.history.back(); }} 
                className="h-12 w-32 dark:bg-violet bg-primary dark:text-primary text-darkest dark:hover:border-2 dark:border-primary hover:border-2 border-darkest font-customFont1 font-bold mt-5 ml-5 text-1xl items-center rounded-full shadow-4xl cursor-pointer overflow-hidden transform hover:scale-x-110 hover:scale-y-110 transition duration-500 ease-out focus:outline-none"
                aria-label="Takaisin"
            >
                Takaisin
            </button>

            {/* Tuotteen kuvat */}
            <div className='flex flex-col justify-between lg:flex-row gap-16 lg:items-start p-5'>
                <div className='flex flex-col gap-5'>
                    <img 
                        src={activeImg} 
                        alt="Soundcraft Spirit FX 16 mikseri etupuolelta" 
                        title="Soundcraft Spirit FX 16" 
                        className='w-full h-full aspect-square object-cover rounded-xl drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'
                    />
                    <div className='flex gap-5 flex-wrap justify-center drop-shadow-[10px_10px_8px_rgba(0,0,0,0.60)]'>
                        {/* Korvattu <img> <button>-elementeillä */}
                        <button 
                            onClick={() => handleImageClick(img1)} 
                            className="w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none"
                            aria-label="Näytä Soundcraft Spirit FX 16 etupuoli"
                        >
                            <img 
                                src={img1} 
                                alt="Soundcraft Spirit FX 16 etupuoli" 
                                title="Soundcraft Spirit FX 16 etupuoli" 
                                className='w-full h-full object-cover rounded-md' 
                            />
                        </button>

                        <button 
                            onClick={() => handleImageClick(img2)} 
                            className="w-32 h-32 rounded-md p-0 border-0 bg-transparent cursor-pointer focus:outline-none"
                            aria-label="Näytä Soundcraft Spirit FX 16 takapuoli"
                        >
                            <img 
                                src={img2} 
                                alt="Soundcraft Spirit FX 16 takapuoli" 
                                title="Soundcraft Spirit FX 16 takapuoli" 
                                className='w-full h-full object-cover rounded-md' 
                            />
                        </button>
                    </div>
                </div>
                
                {/* Tuotteen kuvaus */}
                <div className='dark:bg-violet bg-primary dark:bg-opacity-75 bg-opacity-75 flex gap-4 flex-col lg:w-2/4 p-5 rounded-3xl'>

                    <div>

                        {/* SPEECH */}

                        <div className="flex flex-row items-center">
                            {/* Otsikko h1 */}
                            <h1 className="font-customFont2 text-shadow-lg text-left text-darkest dark:text-primary text-4xl sm:text-6xl md:text-6xl lg:text-6xl xl:text-6xl font-bold leading-none">
                                Mikseri Soundcraft FX 16
                            </h1>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text} />
                            </div> 
                        </div>

                        <br />

                        {/* Teksti */}
                        <p className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-bold'>
                            Studiolaatuinen ääni, olipa kyseessä live-esitys tai studiotyöskentely - Soundcraft Spirit FX16:lla!
                        </p><br />

                        {/* Teksti */}

                        {/* SPEECH */}

                        <div className="flex flex-row items-center">

                            <p className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-bold'>
                                Ominaisuudet
                            </p><br />

                            <div className='speech-title ml-5'> 

                                <TextToSpeech text={text1} />

                            </div>

                        </div>

                        <br />
                        
                        <p className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-light'>
                            Monipuolinen analoginen 4-väyläinen mikseri, joka tuottaa <strong>mainion äänenlaadun</strong> sekä live-esityksissä että studiokäytössä. Se tarjoaa laajan valikoiman ominaisuuksia, jotka tekevät musiikin teosta, miksaamisesta ja äänityksestä entistä nautinnollisempaa ja helpompaa.
                        </p>

                        <br />

                        {/* SPEECH */}

                        <div className="flex flex-row items-center">

                            <h2 className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-bold'>
                                Lisätietoja:
                            </h2>

                            <div className='speech-title ml-5'>

                                <TextToSpeech text={text2} />

                            </div>

                        </div>

                        <ul className="ominaisuudet list-disc font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg sm:text-lg md:text-1xl lg:text-2xl font-light p-4">
                            <li><strong>16 mikrofoniliitäntätuloa ja yhteensä 26 sisääntuloa: </strong> Voit miksata samanaikaisesti useita instrumentteja ja muita äänilähteitä.</li>
                            <li><strong>6 suoraa ulostuloa:</strong> Tallenna ääni moniraitoihin studiossa tai lähetä sitä suoraan PA-järjestelmään keikoilla.</li>
                            <li><strong>Lexiconin tehosteprosessorin 16 ohjelmaa:</strong> Lisää ammattimaisia efektejä kahdella samanaikaisella efektillä.</li>
                            <li><strong>Brittiläinen 3-alueinen taajuuskorjain:</strong> Muokkaa ääntä tarkasti ja luo haluamasi soundi.</li>
                            <li><strong>Neljä apukanavavahvistusta: </strong> Lähetä ääntä monitorointiin, efekteihin tai muihin laitteisiin.</li>
                            <li>Räkkiasennettu</li>
                        </ul>

                        <div className="flex flex-row py-5 gap-2" title='Jaa tämä sivu'>

                            <FacebookShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <TwitterShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <XIcon size={32} round />
                            </TwitterShareButton>

                            <WhatsappShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <WhatsappIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </WhatsappShareButton>

                            <LinkedinShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <LinkedinIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </LinkedinShareButton>

                            <EmailShareButton
                                url={"https://spiderlegsproductions.com/multiefekti-adj-stinger-ii"}
                                className="facebook-share-button flex flex-row items-center"
                            >
                                <EmailIcon size={32} round />
                                <p className='dark:text-primary text-darkest font-customFont1'></p>
                            </EmailShareButton>

                        </div>

                        {/* SPEECH */}
                        <div className="flex flex-row items-center">
                            {/* Hinta */}
                            <h2 
                                title="Hinta" 
                                className='font-customFont1 text-left text-darkest dark:text-primary text-shadow-lg text-3xl sm:text-1xl md:text-2xl lg:text-3xl font-semibold'
                            >
                                Alk. 50€ / vrk
                            </h2>
                            <div className='speech-title ml-5 flex justify-center'>
                                <TextToSpeech text={text3} />
                            </div>
                        </div>

                        {/* Kysele saatavuutta button */}
                        <div className='flex flex-row items-center gap-12 pt-5'>
                            <Link to="/otayhteytta" onClick={() => { window.scrollTo({ top: 0, left: 0, behavior: 'auto' }); }} aria-label="Tiedustele saatavuutta">
                                <button 
                                    title="Tiedustele saatavuutta" 
                                    className="h-12 w-60 dark:bg-primary 
                                        dark:hover:border-2
                                        dark:hover:opacity-90
                                        dark:border-white
                                        dark:text-darkest 

                                        bg-darkest 
                                        hover:border-2
                                        hover:border-opacity-90
                                        hover:opacity-90
                                        border-linkcolhover
                                        text-primary 
                                                
                                        font-customFont1 
                                        font-bold 
                                        text-1xl 
                                        overflow-hidden 
                                        transform hover:scale-x-110 
                                        hover:scale-y-105 transition 
                                        duration-300 ease-in
                                        items-center 
                                        rounded-full 
                                        cursor-pointer" 
                                    style={{
                                        boxShadow: "0 0 20px 3px rgba(255, 255, 255, 0.5)"
                                    }}
                                    aria-label="Tiedustele saatavuutta"
                                >
                                    Tiedustele saatavuutta
                                </button>
                            </Link>
                        </div>

                        <div className='flex flex-wrap place-items-start pt-5'>
                            <div className="bg-darkest/50 grid dark:hover:border-2 dark:border-primary hover:border-2 border-darkest w-24 rounded-xl drop-shadow-[2px_20px_6px_rgba(0,0,0,0.30)]">
                                {/* Tuotteen logo */}
                                <a href="https://mackie.com/" aria-label="Vieraile Mackien sivuilla.">
                                    <img 
                                        src={Soundcraftlogo} 
                                        alt="Soundcraft logo" 
                                        loading="lazy" 
                                        title="Vieraile Soundcraftin sivuilla." 
                                        className="w-full h-full object-contain rounded-xl" 
                                    />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SoundcraftFX16;
